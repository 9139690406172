import React from "react"
import PropTypes from "prop-types"
import { Typography, Box, Grid } from "@mui/material"

import { Container, Section, HammerBreak } from "../Layout"

import { StyledPrivacyPolicy } from "./PrivacyPolicy.styled"

const PrivacyPolicy = ({ children }) => {
  return (
    <Box my={4}>
      <Container>
        <Grid container>
          <Grid item md={2}></Grid>
          <Grid item md={8}>
            <StyledPrivacyPolicy>
              <Typography variant="h3">
                Privacy Policy of Book of The Smith
              </Typography>
              <Typography paragraph>
                Book of The Smith operates the https://www.bookofthesmith.com
                website, which provides the SERVICE.
              </Typography>
              <Typography paragraph>
                This page is used to inform website visitors regarding our
                policies regarding the collection, use, and disclosure of
                Personal Information if anyone decides to use our Service, the
                Book of the Smith website. If you choose to use our Service, you
                agree to the collection and use of information in relation to
                this policy. The Personal Information we collect is used to
                provide and improve the Service. We will not use or share your
                information with anyone except as described in this Privacy
                Policy. Our Privacy Policy was created with the help of the
                Privacy Policy Template Generator.
              </Typography>
              <Typography paragraph>
                The terms used in this Privacy Policy have the same meanings as
                in our Terms and Conditions, accessible at
                https://www.bookofthesmith.com, unless otherwise defined in this
                Privacy Policy.
              </Typography>
              <Typography paragraph variant="h6">
                Information Collection and Use
              </Typography>
              <Typography paragraph>
                For a better experience, while using our Service, we may require
                you to provide us with certain personally identifiable
                information, including but not limited to your name, phone
                number, and postal address. The information that we collect will
                be used to contact or identify you.
              </Typography>
              <Typography paragraph variant="h6">
                Log Data
              </Typography>
              <Typography paragraph>
                We want to inform you that whenever you visit our Service, we
                collect information that your browser sends to us, called Log
                Data. This Log Data may include information such as your
                computer's Internet Protocol ("IP") address, browser version,
                pages of our Service that you visit, the time and date of your
                visit, the time spent on those pages, and other statistics.
              </Typography>
              <Typography paragraph variant="h6">
                Cookies
              </Typography>
              <Typography paragraph>
                Cookies are files with a small amount of data that are commonly
                used as a unique anonymous identifier. These are sent to your
                browser from the website you visit and stored on your computer's
                hard drive. Our website uses these "cookies" to collect
                information and to improve our Service. You can either accept or
                refuse these cookies and know when a cookie is being sent to
                your computer. If you reject our cookies, you may be unable to
                use some portions of our Service.
              </Typography>
              <Typography paragraph variant="h6">
                Service Providers
              </Typography>
              <Typography paragraph>
                We may employ third-party companies and individuals due to the
                following reasons:
              </Typography>
              <Box>
                <Box component="ul">
                  <Box component="li">To facilitate our Service;</Box>
                  <Box component="li">
                    To provide the Service on our behalf;
                  </Box>
                  <Box component="li">
                    To perform Service-related services; or
                  </Box>
                  <Box component="li">
                    To assist us in analyzing the use of our Service.
                  </Box>
                </Box>
              </Box>
              <Typography paragraph>
                We want to inform our Service users that these third parties
                have access to your Personal Information. The reason is to
                perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any
                other purpose.
              </Typography>
              <Typography paragraph variant="h6">
                Security
              </Typography>
              <Typography paragraph>
                We value your trust in providing us with your Personal
                Information; thus, we strive to use commercially acceptable
                means of protecting it. But remember that no method of
                transmission over the internet or method of electronic storage
                is 100% secure and reliable, and we cannot guarantee its
                absolute security.
              </Typography>
              <Typography paragraph variant="h6">
                Links to Other Sites
              </Typography>
              <Typography paragraph>
                Our Service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. Note that
                these external sites are not operated by us. Therefore, we
                strongly advise you to review the Privacy Policy of these
                websites. We have no control over and assume no responsibility
                for the content, privacy policies, or practices of any
                third-party sites or services.
              </Typography>
              <Typography paragraph variant="h6">
                Children's Privacy
              </Typography>
              <Typography paragraph>
                Our Services do not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                children under 13. If we discover that a child under 13 has
                provided us with personal information, we immediately delete
                this from our servers. If you are a parent or guardian and know
                that your child has provided us with personal information,
                please get in touch with us so we can take the necessary
                actions.
              </Typography>
              <Typography paragraph variant="h6">
                Changes to This Privacy Policy
              </Typography>
              <Typography paragraph>
                We may update our Privacy Policy from time to time. Thus, we
                advise you to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page. These changes are effective immediately after they
                are posted on this page.
              </Typography>
              <Typography paragraph variant="h6">
                Contact Us
              </Typography>
              <Typography paragraph>
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to contact us.
              </Typography>
            </StyledPrivacyPolicy>
          </Grid>
          <Grid item md={2}></Grid>
        </Grid>
      </Container>
    </Box>
  )
}

PrivacyPolicy.propTypes = {
  children: PropTypes.node,
}

export default PrivacyPolicy
